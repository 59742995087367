//flow
import React from 'react'

import { chunkArray } from '../../common/utils'

import styles from './FAQs.module.scss'

type FAQProps = {|
  heading: string,
  list: {|
    question: string,
    answer: string,
  |}[],
|}

type Props = {|
  faqs: FAQProps,
|}

export function FAQs({ faqs }: Props) {
  const { heading, list } = faqs

  const chunkedList = chunkArray(list, 2)

  return (
    <div className={styles.container}>
      <h2 className={styles.heading}>{heading}</h2>
      {chunkedList.map(([faq1, faq2]) => (
        <div className={styles.row} key={faq1.question}>
          <div className={styles.card}>
            <h3 className={styles.cardHeading}>{faq1.question}</h3>
            <p
              className={styles.cardAnswer}
              dangerouslySetInnerHTML={{ __html: faq1.answer }}
            />
          </div>
          <div className={styles.card}>
            <h3 className={styles.cardHeading}>{faq2.question}</h3>
            <p
              className={styles.cardAnswer}
              dangerouslySetInnerHTML={{ __html: faq2.answer }}
            />
          </div>
        </div>
      ))}
    </div>
  )
}
