//flow
import React from 'react'
import { isMobile } from 'react-device-detect'

import PageRow from '../../../common/components/PageRow'
import Image from '../../../common/components/Image'
import { Dot } from '../../Dot/Dot'
import { SenecaLinkExternal } from '../../SenecaLink/SenecaLink'

import styles from './ContentSection.module.scss'

type Content = {|
  heading: string,
  subheading: string,
  link: {|
    text: string,
    to: string,
  |},
  firstSupportingInfo: {|
    icon: string,
    text: string,
  |},
  secondSupportingInfo: {|
    icon: string,
    text: string,
  |},
  assetLink: string,
  assetLinkMobile: string,
  assetAlt: string,
  testimonial: {|
    icon: string,
    user: string,
    additionalUserInfo?: string,
    text: string,
  |},
|}

type Props = {|
  content: Content,
|}

export default function ContentSection({
  content: {
    heading,
    subheading,
    link,
    firstSupportingInfo,
    secondSupportingInfo,
    assetLink,
    assetLinkMobile,
    assetAlt,
    testimonial,
  },
}: Props) {
  const asset = isMobile && assetLinkMobile ? assetLinkMobile : assetLink

  return (
    <PageRow className={styles.row}>
      <div className={styles.container}>
        <div className={styles.topRow}>
          <div className={styles.intro}>
            <h2 className={styles.heading}>{heading}</h2>
            <span className={styles.subheading}>{subheading}</span>
            <SenecaLinkExternal link={link.to}>{link.text}</SenecaLinkExternal>
          </div>
          <div className={styles.subInfo}>
            <span className={styles.subInfoIcon}>
              {firstSupportingInfo.icon}
            </span>
            <span className={styles.subInfoText}>
              {firstSupportingInfo.text}
            </span>
          </div>
          <div className={styles.subInfo}>
            <span className={styles.subInfoIcon}>
              {secondSupportingInfo.icon}
            </span>
            <span className={styles.subInfoText}>
              {secondSupportingInfo.text}
            </span>
          </div>
        </div>
        <Image
          src={asset}
          alt={assetAlt}
          widths={{ mobile: 350, tablet: 650, desktop: 826 }}
        />
        <div className={styles.testimonial}>
          <span className={styles.testimonialIcon}>{testimonial.icon}</span>
          <div className={styles.testimonialInfo}>
            <div className={styles.testimonialInfoHeading}>
              {testimonial.user}{' '}
              {testimonial.additionalUserInfo && (
                <>
                  <Dot /> {testimonial.additionalUserInfo}
                </>
              )}
            </div>
            <span className={styles.testimonialInfoText}>
              {testimonial.text}
            </span>
          </div>
        </div>
      </div>
    </PageRow>
  )
}
